.extended-match-grid,
.extended-match-grid-solution {
  position: relative;
  display: grid;
  grid-template-columns: 42% 42%;
  grid-gap: 16%;


  width: 100%;
  max-width: 1400px;
}

.ext-match-left-side {
  width: 100%;
}

.ext-match-right-side {
  width: 100%;
  justify-self: end;
}

.extended-match-grid canvas,
.extended-match-grid-solution canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 16%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.extended-match-grid .ext-match-element,
.extended-match-grid-solution .ext-match-element {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 50px;
  width: 100%;

  padding: 10px 15px;
  margin-bottom: 20px;

  background-color: white;
  border: 1px solid rgb(201, 206, 209);
  border-radius: 5px;
  z-index: 10;
}

.answer-correct .extended-match-grid-solution .ext-match-element {
  background-color: transparent;
  border-color: rgba(21, 104, 60, 0.7);
}

.answer-false .extended-match-grid-solution .ext-match-element {
  background-color: transparent;
  border-color: rgb(194, 149, 144);
}

.extended-match-grid .ext-match-element-text,
.extended-match-grid-solution .ext-match-element-text {
  width: 100%;
}

/* Images */
.extended-match-grid .ext-match-element-text img,
.extended-match-grid-solution .ext-match-element-text img {
  max-height: 400px;
}

/* CIRCLE */
.extended-match-grid .ext-match-element-circle,
.extended-match-grid-solution .ext-match-element-circle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.extended-match-grid .ext-match-element-circle {
  background-color: white;
  border: 2px solid rgb(150, 150, 150);
}

.answer-false .extended-match-grid-solution .ext-match-element-circle {
  background-color: rgb(241, 177, 170);
  border: 2px solid rgb(170, 120, 114);
}

.answer-correct .extended-match-grid-solution .ext-match-element-circle {
  background-color: rgb(145, 202, 172);
  border: 2px solid rgba(21, 104, 60, 0.85);
}

.extended-match-grid .highlight-single-circle {
  border-color: rgb(122, 122, 245);
}

/* Highlight */
.highlight-all-right-circles .ext-match-element-circle,
.highlight-all-left-circles .ext-match-element-circle {
  border-color: rgb(122, 122, 245);
}

/* TODO Do this with transform origin?? */
.ext-match-left-side .ext-match-element-circle {
  right: -10px;
}

.ext-match-right-side .ext-match-element-circle {
  left: -10px;
}

.extended-match-grid .circle-enabled:active {
  border-color: var(--custom-prime-color);
}

.extended-match-grid .circle-disabled:hover {
  cursor: not-allowed;
}

.extended-match-grid .circle-enabled:focus-visible {
  outline: 3px solid var(--custom-prime-color);
  outline-offset: 6px;
}

.remove-lines-btn {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 1px;

  margin-top: 10px;
  padding: 5px 0px;

  font-size: 18px;
  text-decoration: underline;
}

.remove-lines-btn:focus-visible {
  outline: 2px solid var(--custom-prime-color);
  outline-offset: 3px;
}

/* Media Query for Computers (that hover) */
@media only screen and (pointer: fine) {
  .extended-match-grid .circle-enabled:hover {
    cursor: pointer;
    border-color: var(--custom-prime-color);
  }

  .extended-match-grid .remove-lines-btn:hover {
    color: var(--custom-prime-color);
  }
}

/* Media Query for phones */
@media only screen and (pointer: coarse) {
  .extended-match-grid {
    grid-template-columns: 43% 43%;
    grid-gap: 14%;

  }

  .extended-match-grid canvas {
    width: 14%;
  }

  .extended-match-grid .remove-lines-btn:active {
    color: var(--custom-prime-color);
  }

  .extended-match-grid .ext-match-element {
    padding: 8px 12px;
    margin-bottom: 15px;
  }
}