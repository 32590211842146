/* ! To manipulate material UI components sometimes a !important is required */
/* ! Some css properties (mui-checkcolor, color) are defined in the react component  */

.question-multiple-response .MuiFormControlLabel-root {
  margin-bottom: 5px;
  margin-right: 0;
  margin-left: -6px;
  width: calc(100% + 6px) !important;
  border-radius: 3px;
}

.question-multiple-response .MuiFormControlLabel-root {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.question-multiple-response .MuiFormControlLabel-root>.formControlLabel-checkbox:hover {
  background-color: transparent;
}

/* TODO change this to style size prop */
.question-multiple-response .MuiSvgIcon-root {
  font-size: 26px;
}

.question-multiple-response .MuiFormControlLabel-root>.formControlLabel-typography {
  font-size: 18px;
  padding-top: 8px;
  padding-left: 2px;
  padding-right: 5px;
}

.question-multiple-response .Mui-disabled .formControlLabel-typography {
  color: grey;
}

/* For the correction */
.correction-multipleResponse-list-item {
  list-style: none;
  position: relative;
  padding-left: 18px;
}

.correction-multipleResponse-list-item::before {
  content: '■';
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 14px;
}

/* Media Query for phones */
@media only screen and (max-width: 650px) {
  .question-multiple-response .MuiFormControlLabel-root {
    padding: 0px 0 !important;
  }

  /* Override the checkbox size of mui */
  .question-multiple-response .MuiSvgIcon-root {
    font-size: 30px !important;
  }

  .question-multiple-response .MuiFormControlLabel-root>.formControlLabel-typography {
    font-size: 18px;
    padding-top: 10px;
    padding-left: 0;
  }

  .question-multiple-response .MuiFormControlLabel-root>.formControlLabel-checkbox:hover {
    background-color: transparent;
  }

  .correction-multipleResponse-list-item::before {
    left: 0px;
    top: 4px;
    font-size: 10px;
  }
}