.card {
  display: grid;
  grid-template-columns: calc(100% - 105px) 105px;
  grid-template-rows: auto 45px;
  row-gap: 10px;

  padding: 15px 18px 10px 15px;
  min-height: 205px;
  background-color: white;
  color: var(--custom-secondary-color);

  border-radius: 5px;
  /* old shadow, maybe for later  box-shadow: rgb(100 116 139 / 8%) 0px 1px 1px,
    rgb(100 116 139 / 12%) 0px 1px 2px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 3px;

  user-select: none;
  overflow: hidden;
}

.card.disabled {
  opacity: .55;
  cursor: not-allowed;
}

.card-title {
  font-size: 1.28rem;
  line-height: 1.1;
}

.card .card-description {
  padding-top: .2rem;
  line-height: 1.2;
  color: rgb(75, 75, 75);
}

.svg-wrapper {
  color: var(--custom-border-color-lighter);

  place-self: start end;

  height: 105px;
  width: 105px;
}

.svg-wrapper svg {
  height: 100%;
  width: 100%;
}

.card .card-bottom {
  grid-column: 1 / span 2;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid var(--custom-border-color-lighter);
  padding-top: 5px;
}

.card .card-bottom .card-link,
.card .card-bottom .card-button {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 10px;

  font: inherit;
  line-height: 1;
  font-weight: 600;
  text-decoration: none;

  color: var(--custom-prime-color);
  background-color: transparent;

  border: none;
  border-radius: 6px;
}

.card.active .card-bottom .card-link,
.card.active .card-bottom .card-button {
  cursor: pointer;
}

.card.disabled .card-bottom .card-button {
  pointer-events: none;
}

.card.disabled .card-bottom .card-link,
.card.disabled .card-bottom .card-button {
  color: lightgrey;
}

.card .card-bottom .card-link span,
.card .card-bottom .card-button span {
  font-size: 1.2rem;
}

.card .card-bottom .card-link-svg,
.card .card-bottom .card-button-svg {
  margin-left: 5px;
  transform: scale(1.3);
  stroke-width: 30px;
}

.card .card-bottom .popover-button {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 39px;
  height: 100%;
  border-radius: 50%;
  margin-top: 5px;
}

.card .card-bottom .popover-button svg {
  line-height: 0;
  font-size: 20px;
}

/* Accessibility for tabbing  */
.card .card-bottom>*:focus-visible {
  outline: none;
  box-shadow: inset 0px 0px 0px 2px var(--custom-prime-color);
}

/* Media Query for devices that can hover */
@media only screen and (pointer: fine) and (hover: hover) {

  .card.active .card-bottom .card-link:hover,
  .card.active .card-bottom .card-button:hover {
    background-color: var(--custom-tertiary-color);
  }

  .card .card-bottom .popover-button:hover {
    background-color: rgba(0, 0, 0, .03);
  }

  .card .card-bottom .popover-button:hover svg {
    color: var(--custom-prime-color);
  }
}

/* Media Query for small devices */
@media only screen and (max-width: 650px) {
  .card .card-title {
    font-size: 1.3rem;
  }


  .card .card-bottom .card-button,
  .card .card-bottom .card-link {
    padding-left: 5px;
    padding-right: 5px;
  }
}