.editor {
  width: 100%;
  /* min-height: 200px; */
  outline: 1px solid var(--custom-border-color-lighter);

  border-radius: 3px;
  overflow: hidden;
}

.editor:focus-visible {
  box-shadow: 0 0 0 1px var(--custom-prime-color);
}

.editor .editor-toolbar {
  line-height: 0;
  border-bottom: 1px solid var(--custom-border-color-lighter);
  display: flex;
  flex-direction: row;
  padding: 0 5px;
}

.editor .editor-toolbar.disabled {
  cursor: not-allowed;
}

.editor .editor-toolbar.disabled button {
  color: var(--custom-border-color-light);
  pointer-events: none;
}

.editor .editor-toolbar button {
  all: unset;
  border: 0;
  margin: 0;
  line-height: 0;
  cursor: pointer;
  height: 32px;
  margin: 5px 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.editor .editor-toolbar button:active {
  color: var(--custom-prime-color);
}

.editor .editor-toolbar button:focus-visible {
  outline: 1px solid var(--custom-prime-color);
}

.editor .editor-toolbar button svg {
  line-height: 0;
  padding: 3px;

  width: 100%;
  height: 100%;
}

.editor .editor-toolbar button#editor-remove-item svg {
  margin-top: 2px;
}

.lastSelected {
  outline: 1px dashed var(--custom-prime-color);
  outline-offset: 4px;
}

.editor .MuiFormControl-root {
  width: 100%;
}

.editor .MuiFormControlLabel-root {
  margin: 0 0 8px 0;
}

.editor .MuiFormControlLabel-label {
  width: 100%;
  display: flex;
  align-items: center;
}

.editor .editor-label-textarea {
  font: inherit;
  margin-right: 15px;
  flex-grow: 1;
  padding: 4px 6px;

  resize: none;
  outline: none;
  outline-offset: -1px;

  border: 1px solid var(--custom-border-color-light);
  border-radius: 4px;
}

.editor .editor-label-textarea:focus-visible {
  outline: 2px solid var(--custom-prime-color);
}

.editor .editor-content:not(.gap-text) {
  padding: 10px;
  min-height: 170px;
}

.editor .editor-content.gap-text {
  /* TextAreaAutosize has some weird margin at the bottom */
  padding: 10px 10px 0 10px;
}

.editor .editor-message {
  color: var(--custom-border-color-dark);
}

/* Remove the out effect on mui radio. 
The first value is for development, the second is production. 
This is the only way if found to have it still animated but disable the out animation */
.editor .formControlLabel-radio .css-1hhw7if-MuiSvgIcon-root,
.editor .formControlLabel-radio .css-1c4tzn {
  /* Overwrite the out animation for the material UI radio button because on site change it doesn't unmount in time */
  -webkit-transition: -webkit-transform 0ms cubic-bezier(0.4, 0, 1, 1) 0ms !important;
  transition: transform 0ms cubic-bezier(0.4, 0, 1, 1) 0ms !important;
}

/* Disable hover effect on checkbox */
.editor .formControlLabel-checkbox:hover,
.editor .formControlLabel-radio:hover {
  background-color: transparent;
}

.editor .markdown-support {
  text-align: right;
  padding: 3px 8px;
  border-top: 1px solid var(--custom-border-color-lighter);

  line-height: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.editor > .markdown-support > span {
  color: var(--custom-border-color-darker);
  padding-right: 0.5rem;
}

.editor .markdown-support svg {
  height: 1.4rem;
  width: 1.4rem;
  color: var(--custom-border-color-darker);
}

@media screen and (hover: hover) and (pointer: fine) {
  .editor .editor-toolbar.enabled button:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .editor .markdown-support svg:hover {
    color: var(--custom-prime-color);
  }
}
