.sidebar {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;

  top: 0;
  bottom: 0;
  height: 100vh;

  grid-column: 1;
  grid-row: 1/2;



  /* left: 0; */

  /* align-self: start; */

  /*Link width + Link padding (10px on each side)*/
  max-width: 64px;
  /* height: 100%; */

  /* align-self: end end; */

  background-color: rgb(12, 12, 20);

  display: flex;
  flex-direction: column;
  row-gap: 8px;

  padding: 10px 10px 2px 10px;
  user-select: none;
  z-index: 100;
  transition: max-width .3s ease-in-out;
}

.sidebar-expanded {
  max-width: 500px;
}

.category-items {
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 8px;
}

/* Target the child element of the nav */
.sidebar>.category-items a,
.sidebar>.hamburger {
  display: flex;
  flex-direction: row;
  align-items: center;

  color: rgb(187, 192, 199);
  text-decoration: none;
  line-height: 1;
  background-color: transparent;

  padding: 8px 10px;
  /* ((min-width) - svg width) / 2 to center  */
  border-radius: 6px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;

  /* Disable dragable links (only works for webkit) */
  -webkit-user-drag: none;
}

.sidebar .hamburger {
  appearance: none;
  outline: none;
  border: none;
}

.sidebar a>.category-title,
.sidebar .hamburger>p {
  font-size: 17px;
  font-weight: 400;

  padding-left: 16px;
  color: rgb(187, 192, 199);

  white-space: nowrap;
  letter-spacing: .6px;
}

.sidebar .category-items .currentView {
  background-color: rgb(40, 38, 61);
}

.sidebar .category-icon {
  height: 24px;
  min-width: 24px;
  color: rgb(203, 209, 216);
}

.sidebar .settings {
  margin: auto 0 5px 0;
}

/* Css for devices with that can hover */
@media only screen and (pointer: fine) {

  .sidebar .category-items a:hover p,
  .sidebar .category-items a:hover svg,
  .sidebar>.hamburger:hover p,
  .sidebar>.hamburger:hover svg {
    color: var(--custom-tertiary-color);
  }
}

/*Discoverd later that apps developed in electron can easily be ported to run in any other browser and therefore on mobile.
  If you want to change any of the css make sure that it runs in all viewports.
*/
/* Mobile CSS */
@media only screen and (max-width: 650px) {
  .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: auto;

    max-height: 48px;
    min-width: 100vw;

    padding: 0 10px;
  }

  .sidebar .category-items {
    display: none;
    margin-bottom: 30%;
  }

  .sidebar-expanded>.category-items {
    display: flex;
    justify-content: center;
    row-gap: 10px;
  }

  .sidebar .hamburger {
    flex-direction: row-reverse;
    justify-content: space-between;

    margin: 0;
    padding: 8px 6px;

    min-height: 48px;
  }

  .sidebar .hamburger p {
    font-size: 16px;
    padding: 0;
  }

  .sidebar>.category-items a {
    display: none;

    justify-content: center;
    padding: 10px 0px;
  }

  .sidebar a svg {
    display: none;
  }

  .sidebar>.category-items>a>.category-title {
    font-size: 1.3rem;
    padding: 0;
  }

  .sidebar-expanded {
    max-width: 100vw;
    max-height: 100vh;
  }

  .sidebar-expanded>.category-items a {
    display: flex;
  }

  .sidebar .settings {
    margin: 0;
  }

  .sidebar .category-items a:active p,
  .sidebar .category-items a:active svg,
  .sidebar>.hamburger:active p,
  .sidebar>.hamburger:active svg {
    color: var(--custom-tertiary-color);
  }
}