.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 100;
}

.ReactModal__Content.ReactModal__Content--after-open {
  padding: 0 !important;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;

  background-color: white !important;

  border-radius: 5px !important;
  border: none !important;
  z-index: 100 !important;
}

.ReactModal__Content.ReactModal__Content--after-open .title-close-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  padding: 20px 20px 0 25px;
}

.ReactModal__Content.ReactModal__Content--after-open .modal-title {
  font-weight: 500;
}

.ReactModal__Content.ReactModal__Content--after-open .modal-close-btn {
  all: unset;
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 100%;
}

.ReactModal__Content.ReactModal__Content--after-open .modal-close-btn svg {
  width: 100%;
  height: 100%;
  padding: 4px;
  min-width: 38px;
  max-width: 40px;
}

.ReactModal__Content.ReactModal__Content--after-open > .line {
  margin-top: 12px;
  width: 100%;
  height: 2px;
  background-color: var(--custom-border-color-lighter);
}

.ReactModal__Content.ReactModal__Content--after-open .modal-content {
  overflow: auto !important;
}

@media (max-width: 650px) {
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    position: fixed !important;
    inset: 0 !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(0.5px);
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    position: absolute;
    inset: 0 !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 95% !important;
    height: calc(100% - 48px - 20px) !important;
    margin-top: 24px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .title-close-wrapper {
    padding: 20px 15px 0 20px;
  }
}

@media (min-width: 651px) {
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background: rgba(0, 0, 0, 0.6) !important;
    position: absolute !important;
    inset: 0 !important;
    height: 100% !important;
    overflow: visible !important;
    backdrop-filter: blur(0.5px);
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    position: sticky;

    width: 90%;
    max-height: 90vh !important;
    max-width: 1400px;

    overscroll-behavior: contain !important;

    overflow: visible !important;

    margin-left: auto;
    margin-right: auto;

    outline: none;
  }

  .ReactModal__Content.ReactModal__Content--after-open.fit-content {
    position: sticky;
    top: max(10%, 10px);
    right: 0;
    left: 0;
    bottom: 0;
    margin-bottom: 10px;
  }

  .ReactModal__Content.ReactModal__Content--after-open.max-modal {
    inset: 0 !important;
    top: 5vh !important;
    margin-bottom: 5vh;
  }
}
